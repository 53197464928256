@font-face {
  font-family: Edmondsans;
  src: url(../assets/fonts/Edmondsans-Regular.otf) format('opentype'),
       url(../assets/fonts/Edmondsans-Bold.otf) format('opentype'),
       url(../assets/fonts/Edmondsans-Medium.otf) format('opentype');
}

// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// Custom
@import "login";
@import "dashboard";
@import "addMeal";
@import "calendar";
@import "inventory";
@import "stats";
@import "frequentUsers";
@import "custom";
@import "contactus";
@import "settings";
@import "mealmodal";

// ie fixes
@import "ie-fix";
