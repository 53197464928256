.meal-row-item {
  width: 100%;
  background-color: whitesmoke;
}
.meal-row-item:hover {
  background-color: darkgrey;
}

.meal-row-item:active {
  background-color: slategray;
}
