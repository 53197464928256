.data-container {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
}

.right20 {
  margin-left: 20px;
}

.metric-card {
  background-color: #c5c3c4;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.sales-report-container {
  background-color: lightblue;
  color: white;
  padding: 20px;
  border-radius: 10px;
}

.transparent {
  background-color: transparent;
  border-width: 0;
}

.check-container {
  padding: 10px;
  width: 100%;
  align-items: center;
  text-align: center;
}

.header-text {
  font-size: 28px;
}

.table-container {
  width: 100%;
  align-items: center;
  padding: 20px;
}

.center {
  text-align: center;
}

.img-avatar, .avatar > img,
.img-circle {
  max-width: 100%;
  height: auto;
  border-radius: 50em;
  margin-left: 3px;
  margin-right: 3px;
}
