// Here you can add other styles
/* Split the screen in half */
.split {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

.background {
  left: 0;
  background-size: cover;
  width: 100%;
}
/* Control the left side */
.left {
  left: 0;
  width: 70%;
}

/* Control the right side */
.right {
  right: 0;
  width: 30%;
  background-color: #dcddde;
  opacity: 0.8;
}

/* If you want the content centered horizontally and vertically */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Style the image inside the centered container, if needed */
.centered img {
  width: 500px;
  height: 380px;
}

.esans {
  font-family: Edmondsans;
  font-weight: bold;
}

.label {
  background: -webkit-linear-gradient(#0dff4b, #0a9632);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button {
  background: -webkit-linear-gradient(#0dff4b, #0a9632);
  -webkit-background-clip: transparent;
  -webkit-text-fill-color: text;
}

h1 {
  font-size: 72px;
  background: -webkit-linear-gradient(#0dff4b, #0a9632);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
