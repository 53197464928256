.settings-section-title {
  font-size: 18px;
}

.settings-section-description {
  color: #696969;
  font-size: 14px;
}

.settings-section-divider {
  border: solid;
  border-color: #b9b9b9;
  border-width: thin;
}

.bc-red {
  background-color: red;
}

.expiration-date-picker {
  margin-top: -6px;
}


.divider {
  border: solid;
  border-color: #b9b9b9;
  border-width: thin;
}

.center-screen {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
}
