.meal-modal-image {
  width: 10vw;
  height: 10vw;
  border-radius: 25px;
  padding: 10px;
  align-self: center;
}

.meal-modal-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.meal-modal-label {
  font-size: 16px;
  font-family: "Edmondsans"
}

.meal-modal-text {
  font-size: 16px;
  font-family: "Edmondsans";
  font-weight: 800;
}
